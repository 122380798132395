import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from '../App';
import Main from '../page/component/Main';
import ContactUsPage from '../page/view/ContactUsPage';
import NothingPage from '../page/view/NothingPage';
// import NotFoundView from '../page/view/NotFoundView'
function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<NothingPage />} />
                    <Route path="demo" element={<Main/>}  >
                        {/* <Route index element={<Home />} /> */}
                        {/* <Route path=":contact-us" element={<ContactUsPage/>} /> */}
                        <Route path=":product" />
                        <Route path=":product/:detail" />
                    </Route>
                    {/* <Route path="*" element={<NotFoundView />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default Router;