import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        value: undefined,
    },
    reducers: {
        getData: (state, value) => {
            localStorage.setItem('value', JSON.stringify(value));
            state.value = value;
        }
    }
})

// Action creators are generated for each case reducer function
export const { getData } = counterSlice.actions

export default counterSlice.reducer