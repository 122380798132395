import { Language } from '@react-lang/language';
function Footer() {
    return (
        <Language.Consumer>
            {({get}) => 
                <>
                    <hr className="my-5" />
                    <div className="row gx-5 align-items-center" id="footer">
                        <div className="col-md-6 small">{get('copyright')} © Bumi Mandiri Saktitama 2024</div>
                        <div className="col-md-6 text-md-end small">
                            <button>{get('privacyPolicy')}</button> · <button>{get('termsConditions')}</button>
                        </div>
                    </div>
                </>
            }
        </Language.Consumer>
    );
}
export default Footer;