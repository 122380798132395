import Clients from '../component/Clients';
import Services from '../component/Services';
import OurTopClientele from '../component/OurTopClientele';
import Portfolio from '../component/Portfolio';
import About from '../component/About';
function Home() {
    return (
        <>
            <Clients />
            <About />
            <OurTopClientele />
            <Portfolio />
            <Services />
        </>
    );
}
export default Home;