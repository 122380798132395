import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';
import ServiceRequest from '../../service/ServiceRequest';
import MainService from '../../service/MainService';
import Layout1 from '../component/Layout1';
function LandingPage() {
    const [loading, setLoading] = useState((
        <div className='card__image loading'>
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ));
    const dispacth = useDispatch();
    const requestCallback = (response) => {
        if(response) {
            let background = response.background;
            let about = response.about;
            let data = {
                background: background,
                ourBusinessList: response.ourBusinessList,
                about: about,
                client: response.client,
                portfolio: response.galery,
                address: response.address,
                layout: {
                    background: background.thumbnail,
                    typewrite: about.typewrite,
                    title: about.title
                }
            }
            dispacth(getData(data))
            setLoading('');
        }
    }
    const requestService = (lang) => {
        ServiceRequest(MainService(lang), requestCallback);
    }

    return (
        <>
            <Layout1 />
            <div class="d-none"></div>
        </>
    );
}
export default LandingPage;