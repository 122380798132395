import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Language } from '@react-lang/language';
function OurTopClientele() {
    const [portofolio, setPortofolio] = useState();
    const [product, setProduct] = useState();
    const cover = useSelector((state) => state.counter.value);
    const portofolioHtml = portofolio?.map((value, index) => {
        return (
            <div className="col mb-4 portfolio-item construction interior" key={index}>
                <div className="image-link">
                    <img src={value.fileName} className="img-fluid" alt={value.name}/>
                </div>
            </div>
        )
    });
    const productHtml = product?.map((value, index) => {
        return (
            <button className="btn btn-outline-light rounded-pill text-uppercase is-checked" key={index}>{value.product}</button>
        )
    });
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let portfolio = payload.portfolio;
            setPortofolio(portfolio);
            let productUniq = portfolio.filter((obj, index) =>
                portfolio.findIndex((item) => item.product === obj.product) === index
            );
            productUniq.unshift({
                product: "All"
            })
            setProduct(productUniq);
        }
    }, [cover]);
    return (
        <Language.Consumer>
            {({get}) => 
                <section id="portfolio" className="scrollspy-section bg-dark padding-large">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <div className="title">
                                        <span>{get('someOfOur')}</span>
                                    </div>
                                    <h2 className="section-title light">{get('ourCollectionOf')}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div id="filters" className="button-group d-flex flex-wrap gap-4 py-5">
                                {productHtml}
                            </div>
                        </div>
                        <div className="grid p-0 clearfix row row-cols-2 row-cols-lg-3 row-cols-xl-3">
                            {portofolioHtml}
                        </div>
                    </div>
                </section>
            }
        </Language.Consumer>
    );
}
export default OurTopClientele;