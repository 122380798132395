import { useParams } from "react-router-dom";
import ScrollTop from '../component/ScrollTop';
import NavBar from '../component/NavBar';
import Home from '../view/Home';
import ContactUsPage from '../view/ContactUsPage';
import Product from '../component/Product';
import Layout1 from '../component/Layout1';
import Career from "./Career";
function Main() {
    const { product } = useParams();
    const page = () => {
        if(product) {
            if(product === 'contact-us') {
                return (
                    <>
                        <ContactUsPage/>
                    </>
                )
            }
            else if(product === 'product') {
                return (
                    <>
                        <Product/>
                    </>
                )
            }
            else if(product === 'career') {
                return (
                    <>
                        <Career/>
                    </>
                )
            }
        }
        else {
            return (
                <>
                    <Home/>
                </>
            )
        }
    }
    return (
        <>
            <NavBar />
            <Layout1 />
            <main id="main" className="main">
                {page()}
                <ScrollTop />
            </main>
        </>
    );
}
export default Main;