import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RemoveTagHtml from '../../service/RemoveTagHtml';

function Layout1() {
    const [bgImgCover, setBgImgCover] = useState();
    const [title, setTitle] = useState();
    const cover = useSelector((state) => state.counter.value);
    function showTyping(typewrite) {
        var TxtType = function(el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 2000;
            this.txt = '';
            this.tick();
        };
        TxtType.prototype.tick = function() {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];
            this.txt = fullTxt.substring(0, this.txt.length + 1);
            this.el.innerHTML = '<span className="wrap">'+this.txt+'</span>';
            var that = this;
            var delta = 200 - Math.random() * 100;
            if (!(this.txt === fullTxt)) {
                setTimeout(function() {
                    that.tick();
                }, delta);
            }
        };
        var elements = document.getElementsByClassName('typewrite')[0];
        var toRotate = `["${typewrite}"]`;
        var period = '2000';
        if (toRotate) new TxtType(elements, JSON.parse(toRotate), period);
    }
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let layout = payload.layout;
            let background = layout.background;
            let hero = document.getElementById('hero');
            hero.classList.add('lazy-load');
            if(background === payload.background.thumbnail) {
                hero.classList.remove('other-page');
            }
            else {
                hero.classList.add('other-page');
            }
            let bgImg = new Image();
            bgImg.src = background;
            bgImg.onload = function(){
                setBgImgCover(`url(${background})`);
                hero.classList.remove('lazy-load');
            };
            setTitle(RemoveTagHtml(layout.title));
            showTyping(RemoveTagHtml(layout.typewrite));
        }
    }, [cover]);

    return (
        <>
            <section id="hero" className="d-flex flex-column align-items-center" style={{backgroundImage: bgImgCover}}>
                <div className="hero-container">
                    <p>
                        <span className="typewrite"></span>
                    </p>
                    <h1>{title}</h1>
                </div>
                <div className="svg-border-rounded text-light">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.01 144.54 17.34" preserveAspectRatio="none" fill="currentColor">
                        <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                    </svg>
                </div>
            </section>
        </>
    );
}
export default Layout1;