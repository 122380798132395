import Footer from './Footer';
import { Language } from '@react-lang/language';
function LayoutContactUs() {
    return (
        <Language.Consumer>
            {({get}) => 
                <section className="py-10 bg-light">
                    <div className="container px-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 text-center">
                                <h2>{get('cantFindThe')}</h2>
                                <p className="lead mb-5">Hubungi kami, dan kami akan segera menghubungi Anda dengan solusi untuk masalah apa pun yang Anda alami dengan Pemasok Kimia dan Konstruksi.</p>
                            </div>
                        </div>
                        <div className="row gx-5 align-items-center mb-10">
                            <div className="col-lg-4 text-center mb-5 mb-lg-0">
                                <div className="section-preheading">Message Us</div>
                                <a>Start a chat!</a>
                            </div>
                            <div className="col-lg-4 text-center mb-5 mb-lg-0">
                                <div className="section-preheading">Call Anytime</div>
                                <a>(555) 565-1846</a>
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="section-preheading">Email Us</div>
                                <a>support@startbootstrap.com</a>
                            </div>
                        </div>
                        <form className="ng-untouched ng-pristine ng-valid">
                            <div className="row gx-5">
                                <div className="mb-4 col-md-6">
                                    <label htmlFor="inputName" className="mb-2 text-dark">Full name</label>
                                    <input id="inputName" type="text" placeholder="Full name" className="form-control py-4"/>
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label htmlFor="inputEmail" className="mb-2 text-dark">Email</label>
                                    <input id="inputEmail" type="email" placeholder="name@example.com" className="form-control py-4" />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="inputMessage" className="mb-2 text-dark">Message</label>
                                <textarea id="inputMessage" type="text" placeholder="Enter your message..." rows="4" className="form-control py-3"></textarea>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary fw-500 mt-4">Submit Request</button>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </section>
            }
        </Language.Consumer>
    );
}
export default LayoutContactUs;