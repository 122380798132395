import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Language } from '@react-lang/language';
import { getData } from '../../redux/Counter';
import { useNavigate } from "react-router-dom";
function About() {
    const [ourBusiness, setOurBusiness] = useState();
    const [ourBusinessList, setOurBusinessList] = useState();
    const cover = useSelector((state) => state.counter.value);
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const businessImageHtml = (fileName) => {
        return (
            <div className="col-lg-6 content aos-init aos-animate">
                <img src={fileName} className="img-fluid" alt=""/>
            </div>
        );
    }
    const productList = (details) => {
        return details?.map((value, index) => {
            return (
                <li key={index}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 682.667 682.667" className="feather feather-menu">
                        <g>
                            <defs>
                                <clipPath id="b" clipPathUnits="userSpaceOnUse">
                                    <path d="M0 512h512V0H0Z" fill="#000000" opacity="1" data-original="#000000"></path>
                                </clipPath>
                            </defs>
                            <mask id="a">
                                <rect width="100%" height="100%" fill="#ffffff" opacity="1" data-original="#ffffff"></rect>
                            </mask>
                            <g mask="url(#a)">
                                <path d="m0 0-134.174-134.174-63.873 63.872" style={{"strokeWidth":40, "strokeLinecap": "round", "strokeLinejoin": "round", "strokeMiterlimit": 10, "strokeDasharray": "none", "strokeOpacity": 1}} transform="matrix(1.33333 0 0 -1.33333 473.365 251.884)" fill="none" stroke="#000000" data-original="#000000"></path>
                                <g clipPath="url(#b)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                                    <path d="M0 0c0-130.339-105.661-236-236-236S-472-130.339-472 0s105.661 236 236 236S0 130.339 0 0Z" style={{"strokeWidth":40, "strokeLinecap": "round", "strokeLinejoin": "round", "strokeMiterlimit": 10, "strokeDasharray": "none", "strokeOpacity": 1}} transform="translate(492 256)" fill="none" stroke="#000000" data-original="#000000"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>{value.name}</span>
                </li>
            )
        });
    }
    const navigateProduct = (value) =>{
        let name = value.name
        let path = name.replaceAll(' ', '-');
        let payload = {...cover.payload};
        payload.layout = {
            background: value.fileName,
            typewrite: name,
            title: cover.payload.about.title
        };
        payload.product = [value.id];
        dispacth(getData(payload));
        navigate(`/demo/product/${path}`);
    };
    const businessDescriptionHtml = (value) => {
        return (
            <Language.Consumer>
                {({get}) =>
                    <div className="col-lg-6 aos-init aos-animate">
                        <h4>{value.name}</h4>
                        <p>{value.description}</p>
                        <ul>
                            {productList(value.details)}
                        </ul>
                        <button className="read-more" onClick={() => navigateProduct(value)}>
                            <span>{get('readMore')}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512.009 512.009" className="feather feather-menu">
                                <g><path d="M508.625 247.801 392.262 131.437c-4.18-4.881-11.526-5.45-16.407-1.269-4.881 4.18-5.45 11.526-1.269 16.407.39.455.814.88 1.269 1.269l96.465 96.582H11.636C5.21 244.426 0 249.636 0 256.063s5.21 11.636 11.636 11.636H472.32l-96.465 96.465c-4.881 4.18-5.45 11.526-1.269 16.407s11.526 5.45 16.407 1.269c.455-.39.88-.814 1.269-1.269l116.364-116.364c4.511-4.537 4.511-11.867-.001-16.406z" fill="#000000" opacity="1" data-original="#000000"></path></g>
                            </svg>
                        </button>
                    </div>
                }
            </Language.Consumer>
        );
    }
    const ourBusinessHtm = ourBusinessList?.map((value, index) => {
        return (
            <div className="row gy-4" id={`ourBusiness${value.id}`} key={index}>
                {index % 2 == 0 ? businessImageHtml(value.fileName) : businessDescriptionHtml(value)}
                {index % 2 == 1 ? businessImageHtml(value.fileName) : businessDescriptionHtml(value)}
            </div>
        )
    });
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            setOurBusiness(payload.about.our_business);
            setOurBusinessList(payload.ourBusinessList);
        }
    }, [cover]);
    return (
        <Language.Consumer>
            {({get}) => 
                <section id="about" className="about section">
                    <div className="container section-title aos-animate text-center">
                        <h2>{get('ourBusiness')}</h2>
                        {ReactHtmlParser(ourBusiness)}
                    </div>
                    <div className="container">
                        <div className="row gy-4">
                            {ourBusinessHtm}
                        </div>
                    </div>
                </section>
            }
        </Language.Consumer>
    );
}
export default About;