import axios from "axios";

class HttpCommon {
    getHeaders() {
        let headers = {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return headers; 
    }
    requestGet(path) {
        var http = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL
        });
        return http.get(path)
    }
}

export default new HttpCommon();