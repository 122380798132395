
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Language } from '@react-lang/language';
import Footer from './Footer';
function Services() {
    const [connectWithUs, setConnectWithUs] = useState();
    const [facebook, setFacebook] = useState();
    const [tokped, setTokped] = useState();
    const [linkedin, setLinkedin] = useState();
    const [headOffice, setHeadOffice] = useState();
    // const [ourBusiness, setOurBusiness] = useState();
    const cover = useSelector((state) => state.counter.value);
    // const subProductList = (subProduct) => {
    //     return subProduct.map( (value, index) => {
    //         return (
    //             <li key={index}>{value}</li>
    //         )
    //     });
    // }
    // const detailsList = (details) => {
    //     return details.map( (value, index) => {
    //         return (
    //             <li key={index}>
    //                 {value.name}
    //                 <ul>{subProductList(value.subProduct)}</ul>
    //             </li>
    //         )
    //     });
    // }
    // const ourBusinessHtm = ourBusinessList?.map( (value, index) => {
    //     return (
    //     )
    // });
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let address = payload.address;
            setFacebook(address.facebook.address);
            setTokped(address.tokped.address);
            setLinkedin(address.linkedin.address);
            setHeadOffice(address.head_office.address);
            setConnectWithUs(payload.about.connect_with_us);
        }
    }, [cover]);
    return (
        <Language.Consumer>
            {({get}) => 
                <section id="services" className="services section light-background">
                    <div className="container section-title">
                        <h2>{get('connectWithUs')}</h2>
                        {ReactHtmlParser(connectWithUs)}
                    </div>
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-6 col-md-6 d-flex aos-init aos-animate">
                                <div className="service-item position-relative">
                                    {/* <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 512 512" className="feather feather-menu">
                                            <g><path d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z" fill="#000000" opacity="1"></path><path d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z" fill="#ffffff" opacity="1"></path></g>
                                        </svg>
                                        <h4>{facebook}</h4>
                                    </div> */}
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 512 512" className="feather feather-menu">
                                            <g><path d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z" fill="#000000" opacity="1"></path><path d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z" fill="#ffffff" opacity="1"></path></g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="10 10 44 44" className="feather feather-menu">
                                            <g><g data-name="Shady Red"><path fill="#ffffff" d="M32 49a33.008 33.008 0 0 1-11.599-2H20a.988.988 0 0 1-.868-.53C14.192 44.273 11 40.852 11 37v12a4 4 0 0 0 4 4h34a4 4 0 0 0 4-4V37c0 6.627-9.402 12-21 12z" opacity="1"></path><path fill="#000000" d="M36 47a9.01 9.01 0 0 0 9-9V24a1 1 0 0 0-1-1h-7.875a10.052 10.052 0 0 0-4.125.89 10.052 10.052 0 0 0-4.125-.89H20a1 1 0 0 0-1 1v22a.974.974 0 0 0 .132.47c.414.183.831.364 1.27.53zM21 25h6.875a8.042 8.042 0 0 1 3.578.845l.1.05a1 1 0 0 0 .895 0l.1-.05A8.042 8.042 0 0 1 36.125 25H43v13a7.008 7.008 0 0 1-7 7H21z" opacity="1"></path><path fill="#ffffff" d="M20 47h.401a24.675 24.675 0 0 1-1.269-.53A.988.988 0 0 0 20 47z" opacity="1"></path><g fill="#fb325d"><path d="M27.5 38a5.5 5.5 0 1 1 5.5-5.5 5.507 5.507 0 0 1-5.5 5.5zm0-9a3.5 3.5 0 1 0 3.5 3.5 3.504 3.504 0 0 0-3.5-3.5z" fill="#000000" opacity="1"></path><path d="M36.5 38a5.5 5.5 0 1 1 5.5-5.5 5.507 5.507 0 0 1-5.5 5.5zm0-9a3.5 3.5 0 1 0 3.5 3.5 3.504 3.504 0 0 0-3.5-3.5z" fill="#000000" opacity="1"></path><circle cx="36" cy="32.5" r="1" fill="#000000" opacity="1"></circle><path d="M36 34.5a2 2 0 1 1 2-2 2.003 2.003 0 0 1-2 2z" fill="#000000" opacity="1"></path><circle cx="28" cy="32.5" r="1" fill="#000000" opacity="1"></circle><path d="M28 34.5a2 2 0 1 1 2-2 2.003 2.003 0 0 1-2 2zM32 41a.997.997 0 0 1-.707-.293l-2-2A1 1 0 0 1 29 38a3 3 0 0 1 6 0 1 1 0 0 1-.293.707l-2 2A.997.997 0 0 1 32 41zm-.936-3.35.936.936.936-.937a1 1 0 0 0-1.872 0zM38 24h-2v-1a4 4 0 0 0-8 0v1h-2v-1a6 6 0 0 1 12 0z" fill="#000000" opacity="1"></path><path d="M49 54H15a5.006 5.006 0 0 1-5-5V15a5.006 5.006 0 0 1 5-5h34a5.006 5.006 0 0 1 5 5v34a5.006 5.006 0 0 1-5 5zM15 12a3.003 3.003 0 0 0-3 3v34a3.003 3.003 0 0 0 3 3h34a3.003 3.003 0 0 0 3-3V15a3.003 3.003 0 0 0-3-3z" fill="#000000" opacity="1"></path></g></g></g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 510 510" className="feather feather-menu">
                                            <g><path d="M459 0H51C22.95 0 0 22.95 0 51v408c0 28.05 22.95 51 51 51h408c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zM153 433.5H76.5V204H153v229.5zm-38.25-272.85c-25.5 0-45.9-20.4-45.9-45.9s20.4-45.9 45.9-45.9 45.9 20.4 45.9 45.9-20.4 45.9-45.9 45.9zM433.5 433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25 17.851-38.25 38.25V433.5H204V204h76.5v30.6c12.75-20.4 40.8-35.7 63.75-35.7 48.45 0 89.25 40.8 89.25 89.25V433.5z" fill="#000000" opacity="1"></path></g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="5 5 54 54" className="feather feather-menu">
                                            <g><g data-name="Layer 28"><path d="M49.45 3.25h-34.9a11.31 11.31 0 0 0-11.3 11.3v34.9a11.31 11.31 0 0 0 11.3 11.3h34.9a11.31 11.31 0 0 0 11.3-11.3v-34.9a11.31 11.31 0 0 0-11.3-11.3zM32.56 50a.75.75 0 0 1-1.12 0c-.52-.58-12.84-14.4-12.84-22 0-7.83 6-14.21 13.4-14.21S45.4 20.13 45.4 28c0 7.6-12.32 21.42-12.84 22z" fill="#000000" opacity="1"></path><circle cx="32" cy="26.73" r="5.69" fill="#000000" opacity="1"></circle></g></g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12 d-flex aos-init aos-animate">
                                <div className="service-item position-relative">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15866.62716197267!2d106.5646345!3d-6.1766787!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ff00379fcb97%3A0x479250e35fa3f78b!2sPT%20BUMI%20MANDIRI%20SAKTITAMA!5e0!3m2!1sid!2sid!4v1729475440823!5m2!1sid!2sid" width="100%" height="450" style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
            }
        </Language.Consumer>
    );
}
export default Services;