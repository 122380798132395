
function ScrollTop() {
    const goToScroll = () => {
        let element = document.getElementById('hero');
        if(element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <>
            <button type="button" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center" onClick={goToScroll}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" preserveAspectRatio="none">
                    <g><path d="m22.77 16-6-7.28a1 1 0 0 0-1.48 0L9.23 16a1 1 0 0 0 .13 1.4 1 1 0 0 0 1.41-.13L15 12.16V22.8a1 1 0 0 0 2 0V12.16l4.23 5.13A1 1 0 0 0 22.77 16z" opacity="1"></path></g>
                </svg>
            </button>
        </>
    );
}
export default ScrollTop;