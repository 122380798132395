import LandingPage from "./page/view/LandingPage";

function App() {
    return (
        <>
            <LandingPage />
        </>
    );
}
export default App;
